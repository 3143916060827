import { useNuxtApp } from '#app'
import { filterEmptyObjectFields } from '~/helpers'

export const useHttpClient: typeof useFetch = async (
    url,
    options
) => {
    const runtimeConfig = useRuntimeConfig()
    const { locale } = useNuxtApp().$i18n
    let headers = { 'accept-language': locale.value || 'en' }

    if (options && options?.customHeaders) {
        headers = { ...headers, ...options.customHeaders }
    }

    const { data, error, pending, refresh, status } = await useFetch(
        url,
        {
            baseURL: runtimeConfig.public.baseURL,
            headers,
            initialCache: false,
            ...options,
            query: filterEmptyObjectFields(options?.query)
        }
    )

    // console.info(
    //     `FETCH=>${url} LOCALE:${locale.value} OPTIONS: `,
    //     filterEmptyObjectFields(options?.query),
    //     'DATA :=>',
    //     data?.value,
    //     'STATUS',
    //     status.value,
    //     'ERROR',
    //     error?.value
    // )

    if (error?.value?.statusCode === 404) {
        throw createError({
            statusCode: 404,
            message: 'not found',
            fatal: true
        })
    }

    if (error?.value?.statusCode === 500) {
        throw createError({
            statusCode: 500,
            message: `Request ${url} ${filterEmptyObjectFields(options?.query)} return status 500`,
            fatal: true
        })
    }
    return { data, error, pending, refresh, status }
}
